import React, {useState} from "react"
import {graphql, Link} from "gatsby"
import Img from "gatsby-image";
import Layout from "../components/layout";
import vlogStyle from "./vlogTemplate.module.css";
import globalStyle from "../styles/global.module.css";
import {Col, Row, Container, Modal, Form, Alert, Button} from "react-bootstrap";
import env from "../config/env";
import addToMailchimp from "gatsby-plugin-mailchimp";
import vlogTemplateStyle from "./vlogTemplate.module.css";
import formStyle from "../pages/form.module.css";

export default function Template({data}) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark;

  const [modalShow, setModalShow] = useState(false);
  const [modalFormState, setModalFormValues] = useState({email: "",});
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState({ show: false, message: "" });
  const [showAlert, setShowAlert] = useState(false);
  const [showSuccessMessage, setShowSuccesMessage] = useState(false);
  const submitParentingNewsletterUrl = "https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=1e75706ef5";

  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => {setModalShow(true)};

  const validateModalForm = () => {
    let isValid = true;

    if (!modalFormState.email) {
      isValid = false;
      setShowEmailErrorMessage({
        show: true,
        message: "Please enter your email address!"
      })
    }

    return isValid
  };

  const saveSubscribedEmailToMailchimp = async () => {

    try {
      const result = await addToMailchimp(modalFormState.email, {}, submitParentingNewsletterUrl);
      const alreadyAMemberError = modalFormState.email + ` is already subscribed to list`
      if (result.result === 'success') {
        setShowSuccesMessage(true);
      } else {
        if (result.result === 'error' && result.msg.includes(alreadyAMemberError)) {
          setShowAlert({
            show: true,
            msg: 'You already subscribed to list!'
          });
        } else {
          setShowAlert({
            show: true,
            msg: result.msg
          });
        }
      }
    }
    catch (err) {
      console.error(err);
      return false;
    }
  };

  const validateAndSaveData = async () => {
    if (validateModalForm()) {
      await saveSubscribedEmailToMailchimp();
    }
  };

  const handleSubscribeFormChange = e => {
    let value = e.target.value;
    setModalFormValues({
      ...modalFormState,
      [e.target.name]: value,
    });
  };

  return (
    <Layout
      className="site-content"
            title={frontmatter.title}
            path={frontmatter.path}
            description={frontmatter.description}
            image={env.domain + frontmatter.featuredImage.publicURL}
            link={frontmatter.videoSourceURL}
            structuredData={{
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://google.com/article"
        },
        "headline": frontmatter.title,
        "image": [env.domain + frontmatter.featuredImage.publicURL],
        "datePublished": frontmatter.date,
        "author": {
            "@type": "Person",
            "name": frontmatter.author
        },
        "publisher": {
            "@type": "Organization",
            "name": "Moms on Maternity",
            "logo": {
                "@type": "ImageObject",
                "url": env.domain + data.logo.publicURL
            }
        },
        "description": frontmatter.description
    }}
    >
      <Container className={vlogTemplateStyle.vlogContainer}>
        <div>
          <h1 className={[vlogStyle.vlogTitle].join(' ')}>{frontmatter.videoTitle}</h1>
          <hr></hr>
          <div>
            <div className="entry-meta">
              <Link to={frontmatter.path}>
                <span >{frontmatter.date}</span>
              </Link>
              <Link className="link-to-page" to={"/author/" + frontmatter.author}>
                <span>{frontmatter.author}</span>
              </Link>
            </div>

            <div className="workshop-content">
              <Row className="workshopDescription">
                <Col xs={12} md={8} sm={12} className={vlogStyle.videoContent} style={{"textAlign": "center"}}>
                  <iframe src={frontmatter.videoSourceURL}
                          width="640"
                          height="400"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen
                          title={frontmatter.title}>
                  </iframe>
                </Col>

                <Col xs={12} md={4} sm={12}>
                  <h4>Topics</h4>
                  <div className="topicsList">
                    <ul>
                      {frontmatter.topics.map(topic => (
                        <li key={topic} value={topic}>{topic}</li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5 style={{"color": "#fd6d2e"}}>If you like it, please use the thumbs up button and/or subscribe. Episodes released weekly!</h5>
                </Col>
              </Row>

              <Row>
                <Col>
                  If you are interested in events and M.O.M ongoings, please subscribe to our parenting
                  <a href="#" onClick={() => {handleShowModal(); return;}}> newsletter </a>
                  or follow us on <a href="https://www.facebook.com/MomsOnMaternity/" target="_blank"  rel="noreferrer">Facebook</a> or
                  <a href="https://www.instagram.com/momsonmaternity/" target="_blank"  rel="noreferrer"> Instagram</a>.
                </Col>
              </Row>
            </div>

            <hr></hr>
            <div
              className=""
              dangerouslySetInnerHTML={{ __html: html }}
            />

            <Row className="justify-content-center aboutFounder">
              <div className="col-md-2 col-sm-2 col-xs-12">
                <h4>Created By:</h4>
                <Img className="authorPhoto" fluid={frontmatter.authorImage.childImageSharp.fluid} />
                <div className="authorName">
                  <p className={globalStyle.boldStyle}>{frontmatter.author}</p>
                  <a className={globalStyle.redirectLink} href="/vlogs/">View All Vlogs</a>
                </div>

              </div>
              <div className="col-md-9 col-sm-9 col-xs-12">
                <p>{frontmatter.aboutAuthor}</p>
              </div>
            </Row>
          </div>

          <Form.Row>
            <Col>
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={handleCloseModal}
                className="subscribeToNewsletterModal"
                name="subscribeToNewsletterModal"
                id="subscribeToNewsletterModal"
              >
                <Modal.Header className={[vlogTemplateStyle.modalBackground, vlogTemplateStyle.modalHeader].join(' ')}
                              closeButton>
                </Modal.Header>
                <Modal.Body className={vlogTemplateStyle.modalBackground}>

                  <Form.Row className={[vlogTemplateStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                    <Col xs={12} sm={12} md={12}>
                      <h4 className={vlogTemplateStyle.modalTitle}>Subscribe to our parenting newsletter!</h4>
                      <Form.Group as={Col} controlId="formEmail"
                                  className={[vlogTemplateStyle.registerFormGroup, vlogTemplateStyle.formLabel]}>
                        <Form.Label className={[formStyle.formLabel, formStyle.required]}>Email address</Form.Label>
                        <Form.Control
                          size="sm"
                          value={modalFormState.email}
                          name="email"
                          type="email"
                          placeholder="Type your email address"
                          onChange={handleSubscribeFormChange}
                          className={vlogTemplateStyle.formInput}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  {showEmailErrorMessage.show &&
                  <Form.Row className="justify-content-md-center">
                    <Col xs={12} sm={10} md={8} >

                      <Alert
                        variant="danger"
                        onClose={() => setShowEmailErrorMessage({ show: false })}
                        dismissible
                      >
                        {showEmailErrorMessage.message}
                      </Alert>
                    </Col>
                  </Form.Row>
                  }

                  {showAlert &&
                  <Form.Row className="justify-content-md-center">
                    <Col xs={12} sm={10} md={8} >

                      <Alert
                        variant="danger"
                        onClose={() => setShowAlert(false)}
                        dismissible
                      >
                        {showAlert.msg}
                      </Alert>
                    </Col>
                  </Form.Row>
                  }

                  {
                    <Row className="justify-content-md-center">
                      <Col className="text-center">
                        <Button
                          type="button"
                          className={[
                            globalStyle.btnPrimary,
                            vlogTemplateStyle.confirmButton
                          ]}
                          onClick={validateAndSaveData}
                        >
                          Confirm
                        </Button>
                      </Col>
                    </Row>
                  }

                  {showSuccessMessage &&
                  <Form.Row className="justify-content-md-center">
                    <Col xs={12} sm={10} md={8} >

                      <Alert
                        variant="success"
                        onClose={() => setShowSuccesMessage(false)}
                        dismissible
                      >
                        {'Thank you for subscribing!'}
                      </Alert>
                    </Col>
                  </Form.Row>
                  }
                </Modal.Body>
              </Modal>
            </Col>
          </Form.Row>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      rawMarkdownBody
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        videoTitle
        videoSourceURL
        topics
        featuredImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        authorImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        author
        aboutAuthor
        description
      }
    }
    founderPhoto: file(relativePath: { eq: "founder/Aimee-Cruz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "logo-new.jpg" }) {
        publicURL
    }
  }
`;
